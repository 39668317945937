<template>
  <div v-if="config">
    <div>
      <h2 class="text-center">Openbox config</h2>
      <div>
        <label for="file-upload" class="btn btn-success">
          Import
        </label>
        <input @change="importDataOpenbox" id="file-upload" type="file">
        <b-button class="ml-1" variant="warning" @click="exportDataOpenBox">Export</b-button>
      </div>
      <data-table :fields="openBoxfields" :items="openBox_items" ref="openBox" />
    </div>

    <div class="pt-2">
      <h2 class="text-center">Attribute rate config</h2>
      <div>
        <label for="file-upload-attr" class="btn btn-success">
          Import
        </label>
        <input @change="importDataAttrRate" id="file-upload-attr" type="file">
        <b-button class="ml-1" variant="warning" @click="exportDataAttrRate">Export</b-button>
      </div>
      <data-table :fields="attrRate_fields" :items="attrRate_items" :id="'attrRate'" ref="attrRate" />
    </div>
  </div>
</template>
<script>
import service from "../../service"
import DataTable from './DataTable.vue'
import XLSX from "xlsx"
export default {
  components:{
    DataTable,
    XLSX
  },
  props: {
    config_data: { type: Object, required: true }
  },
  data(){
    return{
      config: this.config_data.value,
      openBoxfields: [
        {name: "Tutor box"},
        {name: "Normal"},
        {name: "Good"},
        {name: "Rare"},
        {name: "Epic"},
        {name: "Legendary"},
      ],
      attrRate_fields:[

      ],
      openBox_items: [],
      attrRate_items: []
    }
  },
  created(){
    for (let i=0; i<= 110; i++){
      if (i==0){
        this.attrRate_fields.push({name:""});
      }
      else{
        this.attrRate_fields.push({name: `${i}`})
      }
    }
    this.convertOpenBoxData();
    this.convertAttrRateData();
  },
  methods:{
    convertOpenBoxData(){
      this.openBox_items = [];
      for (let i in this.config["open_rate"]){
        let data = {};
        data["0"] = this.convertQuality(i);
        this.config["open_rate"][i].forEach((item)=>{
          data[item.quality] = item.rate
        })
        this.openBox_items.push(data)
      }
    },
    convertAttrRateData(){
      this.attrRate_items = [];
      for( let i in this.config["attribute_rate"]){
        let data = {};
        data["0"] = this.convertQuality(i);
        this.config["attribute_rate"][i].forEach((item)=>{
          data[item.base_point] = item.rate
        })
        this.attrRate_items.push(data)
      }
    },
    convertQuality(key){
      switch(key){
        case "1":
          return "Normal"
        case "2":
          return "Good"
        case "3":
          return "Rare"
        case "4":
          return "Epic"
        case "5":
          return "Legendary"
        default:
          return key
      }
    },
    convertToKey(quality){
      switch(quality){
        case "Normal":
          return 1
        case "Good":
          return 2
        case "Rare":
          return 3
        case "Epic":
          return 4
        case "Legendary":
          return 5
      }
    },
    exportDataOpenBox(){
      this.$refs.openBox.exportData("open_box");
    },
    exportDataAttrRate(){
      this.$refs.attrRate.exportData("attr_rate");
    },
    importDataOpenbox(event){
      this.file = event.target.files ? event.target.files[0] : null;
      if (this.file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: 'binary' });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          // Setup range
          let range = XLSX.utils.decode_range(ws['!ref']);
          range.s.c = 1;
          range.s.r = 1;
          let newRange = XLSX.utils.encode_range(range);
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, { header:["1","2","3","4","5"], range: newRange});
          let config = {}
          data.forEach((item,index)=>{
            config[index+1] = []
            for (let i in item){
              config[index+1].push({rate: item[i], quality: parseInt(i)})
            }
          })
          this.config["open_rate"] = config;
          this.convertOpenBoxData();
          this.$store.dispatch('pushSuccessNotify', { text: 'Imported successfully!' })
        }
        reader.readAsBinaryString(this.file);
      }
    },
    importDataAttrRate(event){
      this.file = event.target.files ? event.target.files[0] : null;
      if (this.file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: 'binary' });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          // Setup range
          let range = XLSX.utils.decode_range(ws['!ref']);
          range.s.c = 1;
          range.s.r = 1;
          let newRange = XLSX.utils.encode_range(range);
          /* Convert array of arrays */
          let header = []
          for (let i=1; i<= 110; i++){
            header.push(`${i}`)
          }
          const data = XLSX.utils.sheet_to_json(ws, { header: header, range: newRange});
          let config = {}
          data.forEach((item,index)=>{
            config[index+1] = []
            for (let i in item){
              config[index+1].push({rate: item[i], base_point: parseInt(i)})
            }
          })
          this.config["attribute_rate"] = config;
          this.convertAttrRateData();
          this.$store.dispatch('pushSuccessNotify', { text: 'Imported successfully!' })
        }
        reader.readAsBinaryString(this.file);
      }
    }
  }
}
</script>
