<template>
  <div v-if="config">
    <h2>A</h2>
    <data-table v-if="a_rows"
      :fields="a_fields" :rows="a_rows" id="config_a"
      @importFile="importA" @edited="editA"
    />

    <h2 class="mt-3">Gem Cost</h2>
    <data-table v-if="gemcost_rows"
      :fields="gemcost_fields" :rows="gemcost_rows" id="config_gemcost"
      @importFile="importGemcost" @edited="editGemcost"
    />

    <h2 class="mt-3">Y</h2>
    <data-table v-if="y_rows"
      :fields="y_fields" :rows="y_rows" id="config_y"
      @importFile="importY" @edited="editY"
    />

    <h2 class="mt-3">X</h2>
    <config-rate-list v-if="config.x" :schema="x_form_schema" :model="config.x" />

  </div>
</template>
<script>

function arrayToObject(array) {
  let obj = {};
  array.forEach((item, index) => {
    obj[index] = item;
  });
  return obj;
}

let quality_names = {
  1: 'Normal',
  2: 'Good',
  3: 'Rare',
  4: 'Epic',
  5: 'Legendary',
}

const x_form_schema = [
  {
    cols: 3,
    fields: [{ label: '(GGT/$) From', field: 'low', input_type: 'number', disabled: true }]
  },
  {
    cols: 3,
    fields: [{ label: '(GGT/$) To', field: 'high', input_type: 'number', disabled: true }]
  },
  {
    cols: 6,
    fields: [{ label: 'X value (%)', field: 'value', input_type: 'number' }]
  },
]

export default {
  components:{},
  props: {
    config_data: { type: Object, required: true }
  },
  data(){
    return{
      config: this.config_data.value,
      a_fields: null,
      a_rows: null,
      y_fields: null,
      y_rows: null,
      gemcost_fields: null,
      gemcost_rows: null,
      x_form_schema,
      isUpdating: false,
      config_document: false,
    }
  },
  created(){
    this.transformConfigA();
    this.transformGemcost();
    this.transformY();
  },
  methods:{
    transformConfigA() {
      let config_a = this.config.a
      let fields = Object.keys(config_a).map(key => { return { label: key, field: key }})
      fields = [{ label: 'Mint time', field: 'mint_time', disabled: true }, ...fields]
      let rows = config_a.map((item, index) => {
        return {mint_time: index, ...arrayToObject(item)}
      })
      this.a_fields = fields
      this.a_rows = rows
    },
    parseConfigA(rows) {
      return rows.map(row => {
        let row_data = [];
        for (const [key, value] of Object.entries(row)) {
          let index = Number(key);
          if(!isNaN(index)) {
            row_data[index] = Number(value);
          }
        }
        return row_data;
      });
    },
    importA(rows){
      this.a_rows = rows;
      this.config.a = this.parseConfigA(this.a_rows);
    },
    editA() {
      this.config.a = this.parseConfigA(this.a_rows);
    },
    transformGemcost() {
      let config_data = this.config.gem_cost
      let fields = Object.keys(config_data).map(key => { return { label: key, field: key }})
      fields = [{ label: 'Mint time', field: 'mint_time', disabled: true }, ...fields]
      let rows = config_data.map((item, index) => {
        return {mint_time: index, ...arrayToObject(item)}
      })
      this.gemcost_fields = fields
      this.gemcost_rows = rows
    },
    parseGemcost(rows) {
      return rows.map(row => {
        let row_data = [];
        for (const [key, value] of Object.entries(row)) {
          let index = Number(key);
          if(!isNaN(index)) {
            row_data[index] = value;
          }
        }
        return row_data;
      });
    },
    importGemcost(rows){
      this.gemcost_rows = rows;
      this.config.gem_cost = this.parseGemcost(this.gemcost_rows);
    },
    editGemcost() {
      this.config.gem_cost = this.parseGemcost(this.gemcost_rows);
    },
    transformY() {
      let config_data = this.config.y
      let fields = [{ label: 'Quality', field: 'quality_name', disabled: true }, { label: 'Value', field: 'value' }];
      let rows = Object.entries(config_data).map(([key, value]) => {
        return {quality: key, value, quality_name: quality_names[key]};
      })
      this.y_fields = fields
      this.y_rows = rows
    },
    parseY(rows) {
      let data = {};
      for (const {quality, value} of rows) {
        data[quality] = Number(value);
      }
      return data;
    },
    importY(rows){
      this.y_rows = rows;
      this.config.y = this.parseY(this.y_rows);
    },
    editY() {
      this.config.y = this.parseY(this.y_rows);
    },
  }
}
</script>
