<template>
  <div>
    <div class="d-flex justify-content-center">
      <b-button variant="primary">
      <feather-icon icon="PlusIcon" class="mr-50" />
      <span class="align-middle" @click="isModalShow=true">Add Level</span>
    </b-button>
    </div>
    <b-modal
      v-model="isModalShow"
      title="Create new level"
      hide-footer
      size="lg" modal-class="modal-primary"
      :no-close-on-backdrop="false"
    >
      <b-form>
        <b-form-group
          label="Count down time"
          label-for="h-type"
        >
          <b-input type="number" v-model="levelupPrice.down_time" />
        </b-form-group>
        <b-form-group
          label="Levelup token cost"
          label-for="h-type"
        >
          <b-input type="number" v-model="levelupPrice.token_cost" />
        </b-form-group>
        <b-form-group
          label="Gem speedup cost"
          label-for="h-type"
        >
          <b-input type="number" v-model="levelupPrice.speedup_gem" />
        </b-form-group>
      </b-form>
      <div class="d-flex justify-content-between my-2">
        <b-button variant="danger" @click="cancel">Cancel</b-button>
        <b-button type="submit" variant="primary" @click="create">Create</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  data(){
    return{
      levelupPrice: {
        down_time: null,
        token_cost: null,
        speedup_gem: null,
      },
      isModalShow: false
    }
  },
  methods:{
    create(){
      if (!this.levelupPrice.down_time || !this.levelupPrice.token_cost || !this.levelupPrice.speedup_gem){
        this.$store.dispatch('pushErrorNotify', { text: 'All field are required' })
      }
      else{
        this.$emit("create", this.levelupPrice);
        this.isModalShow = false;
      }
    },
    cancel(){
      this.levelupPrice.down_time = null;
      this.levelupPrice.token_cost = null;
      this.levelupPrice.speedup_gem = null;
      this.isModalShow = false;
    }
  }
}
</script>