<template>
  <div class="page-content" v-if="config">
    <h2 class="text-center">Battery cost config</h2>
    <div>
      <label for="file-upload" class="btn btn-success">
        Import
      </label>
      <input @change="parseImportData" id="file-upload" type="file">
      <b-button class="ml-1" variant="warning" @click="exportBateryCostData">Export</b-button>
    </div>
    <div v-if="file" class="pt-1">
      <span>Seleted file: {{ file.name }}</span>
    </div>
    <b-table-simple id="batery-cost-table" caption-top responsive bordered class="pt-2">
      <b-thead head-variant="light">
        <b-tr>
          <b-th :colspan="config.length"> Power point (base + added point)</b-th>
        </b-tr>
        <b-tr>
          <b-th v-for="(power,index) in config" :key="index" class="text-center">
            {{ index + 1 }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td v-for="(power,index) in config" :key="index"
            class="text-center"
            >
              {{ power }}
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script>
import service from "../../service"
import XLSX from "xlsx"
export default {
  props: {
    config_data: { type: Object, required: true }
  },
  data(){
    return {
      config: this.config_data.value,
      file: null,
      isUpdating: false
    }
  },
  created(){
  },
  methods:{
    parseImportData(event){
      this.file = event.target.files ? event.target.files[0] : null;
      if (this.file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: 'binary' });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          // Setup range
          let range = XLSX.utils.decode_range(ws['!ref']);
          range.s.r = 1;
          let newRange = XLSX.utils.encode_range(range);
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, {range: newRange});
          let config = [];
          for (let i in data[0]){
            config.push(data[0][i])
          }
          this.config = config;
        }
        reader.readAsBinaryString(this.file);
        this.$store.dispatch('pushSuccessNotify', { text: 'Imported successfully!' })
      }
    },
    exportBateryCostData(){
      let table = document.getElementById("batery-cost-table");
      let wb = XLSX.utils.table_to_book(table);
      XLSX.writeFile(wb, `batery_cost.xlsx`);
    },
  }
}
</script>
