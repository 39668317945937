<template>
  <div v-if="selected_cond.data">
    <b-modal
      v-model="isModalShow"
      :title="`Edit ${selected_cond.data.low}% -> ${selected_cond.data.high}%`"
      hide-footer
      size="lg" modal-class="modal-primary"
      :no-close-on-backdrop="false"
    >
      <h3>Batery condition</h3>
      <validation-observer
        ref="bateryCond"
      > 
        <validation-provider
          name="min"
          v-slot="validationContext"
          :rules="{ required: true, min_value: 0}"
        >
          <b-form-group label="Min" label-for="h-number">
            <b-input-group size="lg" prepend="%">
              <b-form-input
                v-model="temp_selected_cond.data.low"
                :state="getValidationState(validationContext)"
                type="number"
                aria-describedby="input-min-feedback"
              />
            </b-input-group>
            <b-form-invalid-feedback id="input-min-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          name="max"
          v-slot="validationContext"
          :rules="{ required: true, max_value: 100}"
        >
          <b-form-group label="Max" label-for="h-number">
            <b-input-group size="lg" prepend="%">
              <b-form-input
                v-model="temp_selected_cond.data.high"
                :state="getValidationState(validationContext)"
                type="number"
                aria-describedby="input-max-feedback"
              />
            </b-input-group>
            <b-form-invalid-feedback id="input-max-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          name="value"
          v-slot="validationContext"
          :rules="{ required: true, min_value: 0}"
        >
          <b-form-group label="Value" label-for="h-number">
            <b-form-input
              v-model="temp_selected_cond.data.value"
              :state="getValidationState(validationContext)"
              type="number"
              aria-describedby="input-value-feedback"
            />
            <b-form-invalid-feedback id="input-value-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </validation-observer>
      <div class="d-flex justify-content-between my-2">
        <b-button variant="danger" @click="cancel">Cancel</b-button>
        <b-button type="submit" variant="primary" @click="save">Save</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
export default {
  components:{
    ValidationProvider, ValidationObserver
  },
  props:{
    selected_cond: {type: Object, default: null}
  }, 
  data(){
    return{
      isModalShow: false,
      temp_selected_cond: {
        index: null,
        data: null
      }
    }
  },
  watch:{
    'selected_cond':{
      handler: function(oldVal, newVal){
        this.temp_selected_cond = JSON.parse(JSON.stringify(newVal));
      },
      deep: true
    }
  },
  methods:{
    openModal(){
      this.isModalShow = true;
    },
    async save(){
      let validation = await this.$refs.bateryCond.validate();
      if (!validation){
        this.$store.dispatch("pushErrorNotify", {
          text: "Validate error",
        });
        return
      }
      this.$emit("update_batery_cond", this.temp_selected_cond);
      this.isModalShow = false;
    },
    cancel(){
      this.isModalShow = false;
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  }
}
</script>