<template>
  <b-form-group label="Config Value">
    <b-form-checkbox v-if="dataType == 'boolean'" class="custom-control-primary" switch
      v-model="config_data.value"
    />
    <b-form-input v-else-if="dataType === 'string'" type="text"
      v-model="config_data.value"
      class="custom-control-primary"
    />
    <b-form-input v-else-if="dataType === 'number'" type="number"
      v-model="config_data.value"
      class="custom-control-primary"
    />

    <code-editor v-else v-model="config_data.value" ref="code_editor"
      class="border rounded py-3"
    />

  </b-form-group>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { trueTypeOf } from '@core/utils'
import CodeEditor from '@core/components/code-editor/View.vue'

export default defineComponent({
  setup() {
  },
  components: {
    CodeEditor,
  },
  props: {
    config_data: { type: Object, required: true },
  },
  data() {
    return {
      config: this.config_data.value
    }
  },
  computed: {
    dataType() {
      return trueTypeOf(this.config);
    }
  },
})
</script>
