<template>
  <div class="page-content" v-if="config">
    <h2 class="text-center">Bonus activity</h2>
    <div>
      <label for="file-upload" class="btn btn-success">
        Import
      </label>
      <input @change="importData" id="file-upload" type="file">
      <b-button class="ml-1" variant="warning" @click="exportData">Export</b-button>
    </div>
    <data-table :fields="fields" :items="table_items" ref="dataTable" />
  </div>
</template>
<script>
import DataTable from './DataTable.vue'
import XLSX from "xlsx"
export default {
  components:{
    DataTable
  },
  props: {
    config_data: { type: Object, required: true }
  },
  data(){
    return{
      config: this.config_data.value,
      fields: [
        {name:"Type GG Tutor"},
        {name:"Bonus activity"}
      ],
      table_items: []
    }
  },
  created(){
    this.prepareConfig();
  },
  methods:{
    async prepareConfig(){
      this.table_items = [];
      for (let i of this.config){
        this.table_items.push({
          type: this.convertQuality(i.type),
          bonus_activity: i.bonus_activity
        })
      }
    },
    convertQuality(key){
      switch(key){
        case 1:
          return "Normal"
        case 2:
          return "Good"
        case 3:
          return "Rare"
        case 4:
          return "Epic"
        case 5:
          return "Legendary"
        default:
          return key
      }
    },
    convertToKey(quality){
      switch(quality){
        case "Normal":
          return 1
        case "Good":
          return 2
        case "Rare":
          return 3
        case "Epic":
          return 4
        case "Legendary":
          return 5
      }
    },
    importData(event){
      this.file = event.target.files ? event.target.files[0] : null;
      if (this.file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: 'binary' });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          // Setup range
          let range = XLSX.utils.decode_range(ws['!ref']);
          range.s.c = 0;
          range.s.r = 1;
          let newRange = XLSX.utils.encode_range(range);
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, { header:["type","bonus_activity"], range: newRange});
          let config = [];
          for (let i of data){
            config.push({
              type: this.convertToKey(i.type),
              bonus_activity: i.bonus_activity
            })
          }
          this.config = config;
          this.config_data.value = config;
          this.table_items = [];
          for( let i of this.config){
            this.table_items.push({
              type: this.convertQuality(i.type),
              bonus_activity: i.bonus_activity,
            })
          }
          this.$store.dispatch('pushSuccessNotify', { text: 'Imported successfully!' })
        }
        reader.readAsBinaryString(this.file);
      }
    },
    exportData(){
      this.$refs.dataTable.exportData("bonus_activity");
    }
  }
}
</script>
