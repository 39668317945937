<template>
  <div v-if="selected_cost.data">
    <b-modal
      v-model="isModalShow"
      :title="`Edit level ${temp_selected_cost.index}%`"
      hide-footer
      size="lg" modal-class="modal-primary"
      :no-close-on-backdrop="false"
    >
      <h3>Batery condition</h3>
      <validation-observer
        ref="bateryCost"
      > 
        <b-row>
          <b-col v-for="(power,index) in temp_selected_cost.data" :key="index" cols="4">
            <validation-provider
              :name="`power-${index}`"
              v-slot="validationContext"
              :rules="{ required: true}"
            >
              <b-form-group :label="`Tutor power ${index}`" label-for="h-number">
                <b-form-input
                  v-model="temp_selected_cost.data[index]"
                  :state="getValidationState(validationContext)"
                  type="number"
                  :aria-describedby="`input-power-${index}-feedback`"
                />
                <b-form-invalid-feedback id="`input-power-${index}-feedback`">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>
      <div class="d-flex justify-content-between my-2">
        <b-button variant="danger" @click="cancel">Cancel</b-button>
        <b-button type="submit" variant="primary" @click="save">Save</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
export default {
  components:{
    ValidationProvider, ValidationObserver
  },
  props:{
    selected_cost: {type: Object, default: null}
  }, 
  data(){
    return{
      isModalShow: false,
      temp_selected_cost: {
        index: null,
        data: null
      }
    }
  },
  watch:{
    'selected_cost':{
      handler: function(oldVal, newVal){
        this.temp_selected_cost = JSON.parse(JSON.stringify(newVal));
      },
      deep: true
    }
  },
  methods:{
    openModal(){
      this.isModalShow = true;
    },
    async save(){
      let validation = await this.$refs.bateryCost.validate();
      if (!validation){
        this.$store.dispatch("pushErrorNotify", {
          text: "Validate error",
        });
        return
      }
      this.$emit("update_batery_cost", this.temp_selected_cost);
      this.isModalShow = false;
    },
    cancel(){
      this.isModalShow = false;
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  }
}
</script>