<template>
  <div class="page-content">
    <page-breadcrumb title="Earn Configs" :items="breadcrumbItems" />
    <component v-if="config_document" ref="config_component"
      :is="componentName"
      :config_data="config_document.data"
      @updated="$emit('updated')"
    />
    <action-buttons
      update-button
      back-button
      @update="update"
      :is-updating="isUpdating"
    />
  </div>
</template>
<script>
import service from '../service'

import LevelupPrice from './_components/LevelupPrice.vue'
import RechargePrice from './_components/RechargePrice.vue'
import Openbox from './_components/Openbox.vue'
import Earn from "./_components/Earn.vue"
import BaseActivity from "./_components/BaseActivity.vue"
import BonusActivity from "./_components/BonusActivity.vue"
import LevelupAttrPoint from "./_components/LevelupAttrPoint.vue"
import BatteryCost from "./_components/BatteryCost.vue"
import ScoreRatio from "./_components/ScoreRatio.vue"
import MintConfig from "./_components/MintConfig.vue"
import LootboxConfig from "./_components/Lootbox.vue"
import DefaultConfig from "./_components/DefaultConfig.vue"
export default {
  components:{
    "LEVELUP_PRICE": LevelupPrice,
    "RECHARGE_PRICE": RechargePrice,
    "OPENBOX_CONFIG": Openbox,
    "EARN_CONFIG": Earn,
    "BASE_ACTIVITY": BaseActivity,
    "BONUS_ACTIVITY": BonusActivity,
    "TUTOR_LEVELUP_ATTR_POINT": LevelupAttrPoint,
    "BATTERY_COST": BatteryCost,
    "SCORE_RATIO": ScoreRatio,
    "MINT_CONFIG": MintConfig,
    "LOOTBOX_CONFIG": LootboxConfig,
    "DEFAULT_CONFIG": DefaultConfig,
  },
  data(){
    return{
      config_document: null,
      isUpdating: false,
    }
  },
  computed:{
    configKey(){
      return this.$route.params.config_key;
    },
    componentName(){
      let key = this.configKey;
      let localComponents = Object.keys(this.$options.components);
      return localComponents.includes(key) ? key : "DEFAULT_CONFIG";
    },
    breadcrumbItems() {
      let items = [
        { text: 'Earn Configs', to: { name: 'earn-fomula-config-list' } },
        { text: `${this.configKey}`, active: true },
      ]
      return items
    },
  },
  created() {
    this.getConfig();
  },
  methods:{
    async getConfig(){
      let query = {
        key: this.configKey,
      }
      this.config_document = await service.get(query);
    },
    async update(){
      this.isUpdating = true;
      let valid_data = true;
      if(this.$refs.config_component.validate) {
        valid_data = await this.$refs.config_component.validate();
      }
      if(valid_data) {
        await service.update(this.config_document);
        this.getConfig();
      }
      this.isUpdating = false;
    },
  }
}
</script>
<style>
  input[type="file"] {
    display: none;
  }
  .level{
    color: #3F84E5;
  }
  .level:hover{
    cursor: pointer;
  }
</style>
