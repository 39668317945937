<template>
  <div class="page-content" v-if="config">
    <h2 class="text-center">Levelup Price</h2>
    <div>
      <label for="file-upload" class="btn btn-success">
        Import
      </label>
      <input @change="importData" id="file-upload" type="file">
      <b-button class="ml-1" variant="warning" @click="exportData">Export</b-button>
    </div>
    <data-table :fields="fields" :items="table_items" ref="dataTable" />
    <!-- <levelup-price-create-form class="mt-2" @create="create" /> -->
  </div>
</template>
<script>
import LevelupPriceCreateForm from "./LevelupPriceCreateForm.vue"
import DataTable from "./DataTable.vue"
import XLSX from "xlsx"
export default {
  components:{
    LevelupPriceCreateForm,
    DataTable
  },
  props: {
    config_data: { type: Object, required: true }
  },
  data(){
    return{
      config: this.config_data.value,
      fields: [
        {name: "Level", key:"level", options: { disabled: true }},
        {name: "Phí lên level = Token", key:"token_cost"},
        {name: "Down time (h)", key:"down_time"},
        {name: "Speedup by Gem", key:"speedup_gem"}
      ],
      table_items: [],
      file: null
    }
  },
  created(){
    this.prepareConfig();
  },
  methods:{
    async prepareConfig(){
      this.table_items = [];
      this.config.forEach((item,index)=>{
        this.table_items.push({
          "level": index,
          "token_cost": item.token_cost,
          "down_time": item.down_time,
          "speedup_gem": item.speedup_gem
        })
      })
    },
    importData(event){
      this.file = event.target.files ? event.target.files[0] : null;
      if (this.file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: 'binary' });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          // Setup range
          let range = XLSX.utils.decode_range(ws['!ref']);
          range.s.c = 1;
          range.s.r = 1;
          let newRange = XLSX.utils.encode_range(range);
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, {header:["token_cost","down_time","speedup_gem"],range: newRange});
          this.config = data;
          this.table_items = [];
          this.config.forEach((item,index)=>{
            this.table_items.push({
              "level": index,
              "token_cost": item.token_cost,
              "down_time": item.down_time,
              "speedup_gem": item.speedup_gem
            })
          })
          this.$store.dispatch('pushSuccessNotify', { text: 'Imported successfully!' })
        }
        reader.readAsBinaryString(this.file);
      }
    },
    exportData(){
      this.$refs.dataTable.exportData("levelup_price");
    }
  }
}
</script>
