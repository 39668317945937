<template>
  <div class="p-50">
    <prism-editor class="my-editor px-1 py-2"
      v-model="code" :highlight="highlighter"
    />
    <div class="d-flex my-50">
      <b-button variant="primary" class="btn-icon" size="sm" pill
        v-b-tooltip title="check code"
        @click="update"
      >
        <feather-icon
          icon="CheckIcon"
        />
      </b-button>
      <b-button variant="secondary" class="btn-icon ml-50" size="sm" pill
        v-b-tooltip title="reset code"
        @click="cancel"
      >
        <feather-icon
          icon="XIcon"
        />
      </b-button>
    </div>
    <b-alert
      variant="danger"
      :show="!!error"
    >
      <div class="alert-body">
        <span><strong>Error:</strong> {{ error }}.</span>
      </div>
    </b-alert>
  </div>
</template>

<script>
  // import Prism Editor
  import { PrismEditor } from 'vue-prism-editor';
  import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere

  // import highlighting library (you can use any library you want just return html string)
  import { highlight, languages } from 'prismjs/components/prism-core';
  import 'prismjs/components/prism-clike';
  import 'prismjs/components/prism-javascript';
  import 'prismjs/themes/prism-tomorrow.css'; // import syntax highlighting styles

  export default {
    components: {
      PrismEditor,
    },
    props: {
      value: { default: null },
    },
    data() {
      return {
        code: null,
        code_original: null,
        error: null,
      }
    },
    watch: {
      value: {
        handler() {
          this.code = JSON.stringify(this.value);
          this.code_original = this.code;
        },
        immediate: true,
      },
      code() {
        this.error = null;
      },
    },
    computed: {
      edited() {
        return this.code !== this.code_original;
      }
    },
    created() {
      // console.log(this.value);
      this.code = JSON.stringify(this.value);
      this.code_original = this.code;
    },
    methods: {
      highlighter(code) {
        return highlight(code, languages.js); // languages.<insert language> to return html with markup
      },
      update() {
        let parsed_code;
        try {
          parsed_code = JSON.parse(this.code);
        } catch (error) {
          this.error = error.message;
        }
        if(parsed_code) {
          this.$emit('input', parsed_code);
        }
      },
      cancel() {
        this.code = this.code_original;
      },
      validate() {
        this.update();
      },
    },
  };
</script>

<style>
  /* required class */
  .my-editor {
    /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
    background: #2d2d2d;
    color: #ccc;

    /* you must provide font-family font-size line-height. Example: */
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 5px;
  }

  /* optional class for removing the outline */
  .prism-editor__textarea:focus {
    outline: none;
  }
</style>
