<template>
  <div class="page-content" v-if="config">
    <h2>General</h2>
    <form-generator :model="config" class="mb-3"
      :schema="config_general_schema"
    />

    <h2>Drop Rate (%)</h2>
    <data-table id="drop_rate_table" class="mb-3"
      :fields="drop_rate_fields" :rows="drop_rate_rows"
      @edited="parseDropRate" @importFile="importDropRate"
    />

    <h2>Drop Type</h2>
    <i>Xác Suất mở ra được GGT phụ thuộc vào Luck    V1=(L+CL)^n/rd btw(P1-P2) + m1%</i>
    <i>Xác Suất mở ra được Chipset phụ thuộc vào Agility   V2=(A+CA)^n/rd btw(P1-P2) + m2%</i>
    <i>Xác Suất mở ra được Gem là  V3=100-V1-V2</i>

    <form-generator :model="config.drop_type" class="mb-3 mt-1"
      :schema="drop_type_schema"
    />

    <h2>Chipset Drop Rate</h2>
    <b-row>
      <b-col cols="6">
        <h6>Level</h6>
        <config-rate-list :model="config.drop_amount.chipset.level"
          :schema="ratelist_schema"
        />
      </b-col>
      <b-col cols="6">
        <h6>Type</h6>
        <config-rate-list :model="config.drop_amount.chipset.type" :schema="ratelist_schema" />
      </b-col>
    </b-row>

    <h2>Gem Drop Rate</h2>
    <b-row>
      <b-col cols="12">
        <config-rate-list :model="config.drop_amount.gem" :schema="range_ratelist_schema" />
      </b-col>
    </b-row>

    <h2>GGT Drop Rate</h2>
    <b-row>
      <b-col cols="12">
        <config-rate-list :model="config.drop_amount.ggt" :schema="range_ratelist_schema" />
      </b-col>
    </b-row>

  </div>
</template>
<script>

let quality_names = {
  1: 'Normal',
  2: 'Good',
  3: 'Rare',
  4: 'Epic',
  5: 'Legendary',
}

const drop_type_schema = [
  {
    cols: 4,
    fields: [
      { label: 'n', field: 'n', input_type: 'number', validate: { type: 'number' } },
    ]
  },
  {
    cols: 4,
    fields: [
      { label: 'm1', field: 'm1', input_type: 'number', validate: { type: 'number' } },
      { label: 'm2', field: 'm2', input_type: 'number', validate: { type: 'number' } },
    ]
  },
  {
    cols: 4,
    fields: [
      { label: 'p1', field: 'p1', input_type: 'number', validate: { type: 'number' } },
      { label: 'p2', field: 'p2', input_type: 'number', validate: { type: 'number' } },
    ]
  }
]

const config_general_schema = [
  { cols: 4, fields: [{ label: 'Lock Time (h)', field: 'lock_time', input_type: 'number', validate: { type: 'number' } }] },
  { cols: 4, fields: [{ label: 'Open Cost (gem)', field: 'open_cost', input_type: 'number', validate: { type: 'number' } }] },
  { cols: 4, fields: [{ label: 'Boost Time Cost', field: 'time_boost_cost', input_type: 'number', validate: { type: 'number' } }] },
]

const ratelist_schema = [
  {
    cols: 6,
    fields: [
      { label: 'rate (%)', field: 'rate', input_type: 'number' },
    ]
  },
  {
    cols: 6,
    fields: [
      { label: 'value ', field: 'value', input_type: 'number' },
    ]
  },
];
const range_ratelist_schema = [
  {
    cols: 4,
    fields: [
      { label: 'rate (%)', field: 'rate', input_type: 'number' },
    ]
  },
  {
    cols: 4,
    fields: [
      { label: 'low ', field: 'low', input_type: 'number' },
    ]
  },
  {
    cols: 4,
    fields: [
      { label: 'high ', field: 'high', input_type: 'number' },
    ]
  },
];

export default {
  components:{},
  props: {
    config_data: { type: Object, required: true }
  },
  data(){
    return{
      drop_type_schema,
      config_general_schema,
      ratelist_schema,
      range_ratelist_schema,
      config: this.config_data.value,
      drop_rate_fields: [],
      drop_rate_rows: [],
    }
  },
  computed: {
    breadcrumb_items() {
      return [
        { text: 'Earn Configs', to: { name: 'earn-fomula-config-list' } },
        { text: this.$route.params.config_key, active: true },
      ]
    },
  },
  created(){
    this.prepareConfigs();
  },
  methods:{
    async prepareConfigs(){
      this.transformDropRate();
    },
    transformDropRate() {
      let drop_rate = this.config.drop_rate;
      if(!drop_rate[0]) { return }
      let fields = Object.keys(drop_rate[0]).map(key => { return { label: quality_names[key], field: key }})
      fields = [{ label: 'NFT level', field: 'level' }, ...fields]
      let rows = drop_rate.map((item, index) => {
        return {level: index, ...item}
      })
      this.drop_rate_fields = fields
      this.drop_rate_rows = rows
    },
    parseDropRate() {
      let rows = JSON.parse(JSON.stringify(this.drop_rate_rows));
      let results = rows.map(row => {
        delete row.level;
        return row;
      });
      this.config.drop_rate = results;
    },
    importDropRate(rows) {
      this.drop_rate_rows = rows;
      this.parseDropRate();
    },
  }
}
</script>
