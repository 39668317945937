<template>
  <div class="page-content" v-if="config">
    <h2 class="text-center">Recharge price</h2>
    <div>
      <label for="file-upload" class="btn btn-success">
        Import
      </label>
      <input @change="importData" id="file-upload" type="file">
      <b-button class="ml-1" variant="warning" @click="exportData">Export</b-button>
    </div>
    <data-table :fields="fields" :items="table_items" ref="dataTable" />
  </div>
</template>
<script>
import service from "../../service"
import RechargePriceCreateForm from "./RechargePriceCreateForm.vue"
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import DataTable from "./DataTable.vue"
import XLSX from "xlsx"
export default {
  components:{
    RechargePriceCreateForm,
    ValidationProvider, ValidationObserver,
    DataTable
  },
  props: {
    config_data: { type: Object, required: true }
  },
  data(){
    return{
      config: this.config_data.value,
      selectedLevel: {
        key: null,
        data: null
      },
      isModalShow: false,
      isUpdating: false,
      fields:[{name: "Level", key:"level"},
        {name: "Normal", key:"1"},
        {name: "Good", key:"2"},
        {name: "Rare", key:"3"},
        {name: "Epic", key:"4"},
        {name: "Legendary", key:"5"}
      ],
      table_items:[]
    }
  },
  created(){
    this.prepareConfig();
  },
  methods:{
    async prepareConfig(){
      this.table_items = [];
      this.config["1"].forEach((item, index)=>{
        let data = {
          "1": index,
          "2": this.config["1"][index],
          "3": this.config["2"][index],
          "4": this.config["3"][index],
          "5": this.config["4"][index],
          "6": this.config["5"][index],
        }
        this.table_items.push(data)
      })
    },
    async save(){
      let validation = await this.$refs.rechargePrice.validate();
      if(!validation){
        this.$store.dispatch("pushErrorNotify", {
          text: "Validate error",
        });
      }
      else{
        this.selectedLevel.data = this.selectedLevel.data.map((x)=>{
          return parseFloat(x)
        })
        this.config[this.selectedLevel.key] = this.selectedLevel.data;
        this.isModalShow = false;
      }
    },
    importData(event){
      this.file = event.target.files ? event.target.files[0] : null;
      if (this.file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: 'binary' });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          // Setup range
          let range = XLSX.utils.decode_range(ws['!ref']);
          range.s.c = 1;
          range.s.r = 1;
          let newRange = XLSX.utils.encode_range(range);
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, { header:["1","2","3","4","5"], range: newRange});
          let config = {
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
          }
          for (let i of data){
            config[1].push(i[1]);
            config[2].push(i[2]);
            config[3].push(i[3]);
            config[4].push(i[4]);
            config[5].push(i[5]);
          }
          this.config = config;
          this.table_items = [];
          this.config["1"].forEach((item, index)=>{
            let data = {
              "1": index,
              "2": this.config["1"][index],
              "3": this.config["2"][index],
              "4": this.config["3"][index],
              "5": this.config["4"][index],
              "6": this.config["5"][index],
            }
            this.table_items.push(data)
          })
          this.$store.dispatch('pushSuccessNotify', { text: 'Imported successfully!' })
        }
        reader.readAsBinaryString(this.file);
      }
    },
    exportData(){
      this.$refs.dataTable.exportData();
    }
  }
}
</script>
