<template>
  <div v-if="config">
    <div>
      <h2 class="text-center">Batery condition config</h2>
      <b-row class="mt-2">
        <b-col
          v-for="(cond, index) in config.batery_cond"
          :key="index"
          class="col-lg-2 col-md-2 col-12"
        >
          <b-card class="text-center level"
            @click="selectedCond.index = index;
              selectedCond.data = cond;
              openBateryCondModal()"
          >
            <div>
              {{ cond.low }}% -> {{ cond.high }}%
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div class="pt-3">
      <h2 class="text-center">Earn time params</h2>
      <b-row class="mt-2"
        v-for="(params, index) in config.time_params"
        :key="index"
      >
        <!-- {{ convertTimeParams(index) }} -->
        <b-col
          v-for="(param, paramIndex) in params"
          :key="paramIndex"
        >
          <b-card class="text-center level"
            @click="selectedParam.childIndex = paramIndex;
              selectedParam.index = index;
              selectedParam.data = param;
              openTimeParamModal()"
          >
            <div>
              {{ param.name }}
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div class="pt-3">
      <h2 class="text-center">Random number</h2>
      <validation-observer
        ref="randomNumber"
      >
        <validation-provider
          name="min"
          v-slot="validationContext"
          :rules="{ required: true, min_value: 0}"
        >
          <b-form-group label="Min" label-for="h-number">
              <b-form-input
                v-model="config.random_numb.low"
                :state="getValidationState(validationContext)"
                type="number"
                aria-describedby="input-min-feedback"
              />
            <b-form-invalid-feedback id="input-min-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          name="max"
          v-slot="validationContext"
          :rules="{ required: true}"
        >
          <b-form-group label="Max" label-for="h-number">
              <b-form-input
                v-model="config.random_numb.high"
                :state="getValidationState(validationContext)"
                type="number"
                aria-describedby="input-max-feedback"
              />
            <b-form-invalid-feedback id="input-max-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <h2 class="text-center">Biến số n</h2>
        <validation-provider
          name="n"
          v-slot="validationContext"
          :rules="{ required: true}"
        >
          <b-form-group label="n" label-for="h-number">
              <b-form-input
                v-model="config.n"
                :state="getValidationState(validationContext)"
                type="number"
                aria-describedby="input-n-feedback"
              />
            <b-form-invalid-feedback id="input-n-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </validation-observer>
    </div>
    <earn-batery-cond-modal ref="EarnBateryCondModal"
      :selected_cond="selectedCond"
      @update_batery_cond="updateBateryCond"
    />
    <earn-batery-cost-modal ref="EarnBateryCostModal"
      :selected_cost="selectedCost"
      @update_batery_cost="updateBateryCost"
    />
    <earn-time-param-modal ref="EarnTimeParamModal"
      :selected_param="selectedParam"
      @update_time_param="updateTimeParam"
    />
  </div>
</template>
<script>
import EarnBateryCondModal from "./modals/EarnBateryCondModal.vue"
import EarnBateryCostModal from "./modals/EarnBateryCostModal.vue"
import EarnTimeParamModal from "./modals/EarnTimeParamModel.vue"
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import XLSX from "xlsx"
export default {
  components:{
    EarnBateryCondModal,
    EarnBateryCostModal,
    EarnTimeParamModal,
    ValidationProvider, ValidationObserver
  },
  props: {
    config_data: { type: Object, required: true }
  },
  data(){
    return{
      config: this.config_data.value,
      selectedCond:{
        index: null,
        data: null
      },
      selectedCost: {
        index: null,
        data: null
      },
      selectedParam:{
        index: null,
        childIndex: null,
        data: null
      },
      isUpdating: false,
      file: null
    }
  },
  methods:{
    openBateryCondModal(){
      this.$refs.EarnBateryCondModal.openModal();
    },
    openBateryCostModal(){
      this.$refs.EarnBateryCostModal.openModal();
    },
    openTimeParamModal(){
      this.$refs.EarnTimeParamModal.openModal();
    },
    updateBateryCond(update_data){
      this.config.batery_cond[update_data.index] = update_data.data;
      this.$forceUpdate()
    },
    updateBateryCost(update_data){
      this.config.batery_cost[update_data.index] = update_data.data;
      this.$forceUpdate()
    },
    updateTimeParam(update_data){
      this.config.time_params[update_data.index][update_data.childIndex] = update_data.data;
      this.$forceUpdate()
    },
    async validate(){
      return await this.$refs.randomNumber.validate();
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    convertTimeParams(index){
      switch(index){
        case 1: case "1":
          return "Arena"
        case 2: case "2":
          return "Pronun"
        case 3: case "3":
          return "Breaking"
      }
    },
    parseImportData(event){
      this.file = event.target.files ? event.target.files[0] : null;
      if (this.file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: 'binary' });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          // Setup range
          let range = XLSX.utils.decode_range(ws['!ref']);
          range.s.r = 1;
          let newRange = XLSX.utils.encode_range(range);
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, {range: newRange});
          this.config.batery_cost = data[0];
        }
        reader.readAsBinaryString(this.file);
        this.$store.dispatch('pushSuccessNotify', { text: 'Imported successfully!' })
      }
    },
    exportBateryCostData(){
      let table = document.getElementById("batery-cost-table");
      let wb = XLSX.utils.table_to_book(table);
      XLSX.writeFile(wb, `batery_cost.xlsx`);
    },
    changeBateryCost(){
      this.config.batery_cost[this.selectedCost.index] = this.selectedCost.data;
    }
  }
}
</script>
