<template>
  <div v-if="selected_param.data">
    <b-modal
      v-model="isModalShow"
      :title="`Edit ${temp_selected_param.data.name}`"
      hide-footer
      size="lg" modal-class="modal-primary"
      :no-close-on-backdrop="false"
    >
      <validation-observer
        ref="timeParam"
      > 
        <validation-provider
          v-if="selected_param.data.type=='value'"
          name="value"
          v-slot="validationContext"
          :rules="{ required: true, min_value: 0}"
        >
          <b-form-group label="Time parameter" label-for="h-number">
            <b-input-group size="lg" prepend="%">
              <b-form-input
                v-model="temp_selected_param.data.value"
                :state="getValidationState(validationContext)"
                type="number"
                aria-describedby="input-time-parameter-feedback"
              />
            </b-input-group>
            <b-form-invalid-feedback id="input-time-parameter-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <div v-if="temp_selected_param.data.type=='compound'">
          <div v-for="(child,index) in temp_selected_param.data.children" :key="index" class="config-dash-border mb-2">
            <validation-provider
              :name="`floor-level-${index}`"
              v-slot="validationContext"
              :rules="{ required: true, min_value: 0}"
            >
              <b-form-group label="Floor level" label-for="h-number">
                  <b-form-input
                    v-model="child.low"
                    :state="getValidationState(validationContext)"
                    type="number"
                    :aria-describedby="`input-floor-${index}-feedback`"
                  />
                <b-form-invalid-feedback :id="`input-floor-${index}-feedback`">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              :name="`ceil-level-${index}`"
              v-slot="validationContext"
              :rules="{ required: true, min_value: 0}"
            >
              <b-form-group label="Ceil level" label-for="h-number">
                <b-form-input
                  v-model="child.high"
                  :state="getValidationState(validationContext)"
                  type="number"
                  :aria-describedby="`input-ceil-${index}-feedback`"
                />
                <b-form-invalid-feedback :id="`input-ceil-${index}-feedback`">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              :name="`time-parameter-${index}`"
              v-slot="validationContext"
              :rules="{ required: true, min_value: 0}"
            >
              <b-form-group label="Time parameter" label-for="h-number">
                <b-input-group size="lg" prepend="%">
                  <b-form-input
                    v-model="child.value"
                    :state="getValidationState(validationContext)"
                    type="number"
                    :aria-describedby="`input-time-parameter-${index}-feedback`"
                  />
                </b-input-group>
                <b-form-invalid-feedback :id="`input-time-time-parameter-${index}-feedback`">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>
        </div>
      </validation-observer>
      <div class="d-flex justify-content-between my-2">
        <b-button variant="danger" @click="cancel">Cancel</b-button>
        <b-button type="submit" variant="primary" @click="save">Save</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
export default {
  components:{
    ValidationProvider, ValidationObserver
  },
  props:{
    selected_param: {type: Object, default: null}
  }, 
  data(){
    return{
      isModalShow: false,
      temp_selected_param: {
        index: null,
        data: null
      }
    }
  },
  watch:{
    'selected_param':{
      handler: function(oldVal, newVal){
        this.temp_selected_param = JSON.parse(JSON.stringify(newVal));
      },
      deep: true
    }
  },
  methods:{
    openModal(){
      this.isModalShow = true;
    },
    async save(){
      let validation = await this.$refs.timeParam.validate();
      if (!validation){
        this.$store.dispatch("pushErrorNotify", {
          text: "Validate error",
        });
        return
      }
      this.$emit("update_time_param", this.temp_selected_param);
      this.isModalShow = false;
    },
    cancel(){
      this.isModalShow = false;
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  }
}
</script>
<style scoped>
  .config-dash-border{
    padding: 5px;
    border: 1px dashed gray;
  }
</style>