<template>
  <div>
    <div class="d-flex justify-content-center">
      <b-button variant="primary">
      <feather-icon icon="PlusIcon" class="mr-50" />
      <span class="align-middle" @click="isModalShow=true">Add Level</span>
    </b-button>
    </div>
    <b-modal
      v-model="isModalShow"
      title="Create new level"
      hide-footer
      size="lg" modal-class="modal-primary"
      :no-close-on-backdrop="false"
    >
      <b-form>
        <b-form-group
          label="Recharge price"
          label-for="h-type"
        >
          <b-input type="number" v-model="rechargePrice" />
        </b-form-group>
      </b-form>
      <div class="d-flex justify-content-between my-2">
        <b-button variant="danger" @click="cancel">Cancel</b-button>
        <b-button type="submit" variant="primary" @click="create">Create</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  data(){
    return{
      rechargePrice: null,
      isModalShow: false
    }
  },
  methods:{
    create(){
      if (!this.rechargePrice){
        this.$store.dispatch('pushErrorNotify', { text: 'All field are required' })
      }
      else{
        this.$emit("create", this.rechargePrice);
        this.isModalShow = false;
      }
    },
    cancel(){
      this.rechargePrice = null;
      this.isModalShow = false;
    }
  }
}
</script>