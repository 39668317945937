<template>
  <div class="page-content" v-if="config">
    <div>
      <h2 class="text-center">Score ratio config</h2>
      <b-row class="mt-2">
        <b-col
          v-for="(ratio, index) in config"
          :key="index"
          class="col-lg-2 col-md-2 col-12"
        >
          <b-card class="text-center level"
            @click="selectedCond.index = index;
              selectedCond.data = ratio;
              openScoreRatioModal()"
          >
            <div>
              {{ ratio.min }}% -> {{ ratio.max }}%
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <score-ratio-modal ref="ScoreRatioModal"
      :selected_cond="selectedCond"
      @update_score_ratio="updateScoreRatio"
    />
  </div>
</template>
<script>
import ScoreRatioModal from "./modals/ScoreRatioModal.vue"
export default {
  components:{
    ScoreRatioModal
  },
  props: {
    config_data: { type: Object, required: true }
  },
  data(){
    return{
      config: this.config_data.value,
      selectedCond:{
        index: null,
        data: null
      },
      isUpdating: false,
      file: null
    }
  },
  created(){
  },
  methods:{
    updateScoreRatio(update_data){
      this.config[update_data.index] = update_data.data;
      this.$forceUpdate()
    },
    openScoreRatioModal(){
      this.$refs.ScoreRatioModal.openModal();
    },
  }
}
</script>
