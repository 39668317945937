<template>
  <div>
    <b-table-simple :id="id" caption-top responsive bordered class="pt-2">
      <b-thead head-variant="light">
        <!-- <b-tr>
          <b-th :colspan="config.data.value.batery_cost.length"> Power point (base + added point)</b-th>
        </b-tr> -->
        <b-tr>
          <b-th v-for="(field,index) in fields" :key="field+index" class="text-center">
            {{ field.name }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(item,index) in items" :key="index">
          <b-td v-for="(value,key) in item" :key="key+index" 
            @click="selectedItem.index = index; selectedItem.data = value; selectedItem.key = key; getFieldOptions(key);"
            class="text-center"
            >
            <!-- <b-input v-if="selectedItem.index == index && selectedItem.key == key && !selectedItem.options.disabled" v-model="selectedItem.data"
              @input="changeData"
            /> -->
            <span> {{ value }} </span>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script>
import XLSX from "xlsx"
export default {
  props:{
    fields: {type: Array, default: null},
    items: {type: Array, default: null},
    rootData: {type: Object, default: null},
    id: {type: String, default: "table"}
  },
  data(){
    return{
      selectedItem: {
        index: null,
        data: null,
        key: null,
        options: {},
      }
    }
  },
  methods:{
    // changeData(){
    //   this.$emit("change_data", this.selectedItem)
    // },
    getFieldOptions(key){
      let field = this.fields.find((field)=>{
        return field.key == key
      })
      this.selectedItem.options = field.options ? field.options : {};
    },
    exportData(excel_name = "data"){
      let table = document.getElementById(this.id);
      let wb = XLSX.utils.table_to_book(table);
      XLSX.writeFile(wb, `${excel_name}.xlsx`);
    },
  }
}
</script>