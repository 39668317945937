<template>
  <div class="page-content" v-if="config">
    <h2 class="text-center">Base activity</h2>
    <div>
      <label for="file-upload" class="btn btn-success">
        Import
      </label>
      <input @change="importData" id="file-upload" type="file">
      <b-button class="ml-1" variant="warning" @click="exportData">Export</b-button>
    </div>
    <data-table :fields="fields" :items="table_items" ref="dataTable" />
  </div>
</template>
<script>
import service from "../../service"
import RechargePriceCreateForm from "./RechargePriceCreateForm.vue"
import DataTable from "./DataTable.vue"
import XLSX from "xlsx"
export default {
  components:{
    RechargePriceCreateForm,
    DataTable
  },
  props: {
    config_data: { type: Object, required: true }
  },
  data(){
    return{
      config: this.config_data.value,
      fields: [
        {name:"Số GG Tutor sở hữu Min", key:"min_tutor_owned"},
        {name:"Số GG Tutor sở hữu Max", key: "max_tutor_owned"},
        {name:"Base activity", key:"base_activity"}
      ],
      table_items: []
    }
  },
  created(){
    this.prepareConfig();
  },
  methods:{
    async prepareConfig(){
      this.table_items = [];
      for( let i of this.config){
        this.table_items.push({
          min_tutor_owned: i.min_tutor_owned,
          max_tutor_owned: i.max_tutor_owned,
          base_activity: i.base_activity
        })
      }
    },
    async update(){
      this.isUpdating = true;
      await service.update(this.config);
      this.prepareConfig();
      this.isUpdating = false;
    },
    importData(event){
      this.file = event.target.files ? event.target.files[0] : null;
      if (this.file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: 'binary' });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          // Setup range
          let range = XLSX.utils.decode_range(ws['!ref']);
          range.s.c = 0;
          range.s.r = 1;
          let newRange = XLSX.utils.encode_range(range);
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, { header:["min_tutor_owned","max_tutor_owned","base_activity"], range: newRange});
          this.config = data;
          this.config_data.value = data;
          this.table_items = [];
          for( let i of this.config){
            this.table_items.push({
              min_tutor_owned: i.min_tutor_owned,
              max_tutor_owned: i.max_tutor_owned,
              base_activity: i.base_activity
            })
          }
          this.$store.dispatch('pushSuccessNotify', { text: 'Imported successfully!' })
        }
        reader.readAsBinaryString(this.file);
      }
    },
    exportData(){
      this.$refs.dataTable.exportData("base_activity");
    }
  }
}
</script>
